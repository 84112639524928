const GlitchEmoji = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.16675 1.75H2.33341V21H1.16675V1.75Z" fill="#CCD6DD" />
    <path
      d="M1.75 3.5C2.7165 3.5 3.5 2.7165 3.5 1.75C3.5 0.783502 2.7165 0 1.75 0C0.783502 0 0 0.783502 0 1.75C0 2.7165 0.783502 3.5 1.75 3.5Z"
      fill="#F4900C"
    />
    <path
      d="M4.66675 16.3337H1.75008C1.42808 16.3337 1.16675 16.0729 1.16675 15.7503C1.16675 15.4277 1.42808 15.167 1.75008 15.167H4.66675C4.98875 15.167 5.25008 15.4277 5.25008 15.7503C5.25008 16.0729 4.98875 16.3337 4.66675 16.3337Z"
      fill="#66757F"
    />
    <path
      d="M17.5 15.75C17.5 14.4614 21.1219 12.25 19.8333 12.25H14.9059C14.2042 11.396 12.2255 10.5 10.7917 10.5C9.35725 10.5 8.9075 11.396 8.78792 12.25H5.25C3.96142 12.25 3.5 13.2947 3.5 14.5833V16.9167C3.5 18.2052 3.96142 19.25 5.25 19.25H19.8333C21.1219 19.25 17.5 17.0386 17.5 15.75Z"
      fill="#DD2E44"
    />
    <path
      d="M6.41675 16.333C7.38325 16.333 8.16675 15.5495 8.16675 14.583C8.16675 13.6165 7.38325 12.833 6.41675 12.833C5.45025 12.833 4.66675 13.6165 4.66675 14.583C4.66675 15.5495 5.45025 16.333 6.41675 16.333Z"
      fill="white"
    />
    <path
      d="M6.125 15.167C6.60825 15.167 7 14.7752 7 14.292C7 13.8087 6.60825 13.417 6.125 13.417C5.64175 13.417 5.25 13.8087 5.25 14.292C5.25 14.7752 5.64175 15.167 6.125 15.167Z"
      fill="black"
    />
    <path
      d="M19.8334 12.25H8.72788C9.93422 13.5835 11.494 16.0213 8.7063 19.25H19.8334C21.122 19.25 17.5 17.0386 17.5 15.75C17.5 14.4614 21.122 12.25 19.8334 12.25Z"
      fill="#F4ABBA"
    />
    <path
      d="M4.66675 6.99967H1.75008C1.42808 6.99967 1.16675 6.73834 1.16675 6.41634C1.16675 6.09434 1.42808 5.83301 1.75008 5.83301H4.66675C4.98875 5.83301 5.25008 6.09434 5.25008 6.41634C5.25008 6.73834 4.98875 6.99967 4.66675 6.99967Z"
      fill="#66757F"
    />
    <path
      d="M17.5 6.41699C17.5 5.12841 21.1219 2.91699 19.8333 2.91699H14.9065C14.2048 2.06299 12.2261 1.16699 10.7917 1.16699C9.35725 1.16699 8.9075 2.06299 8.78792 2.91699H5.25C3.96142 2.91699 3.5 3.96174 3.5 5.25033V7.58366C3.5 8.87224 3.96142 9.91699 5.25 9.91699H19.8333C21.1219 9.91699 17.5 7.70558 17.5 6.41699Z"
      fill="#55ACEE"
    />
    <path
      d="M6.41675 7C7.38325 7 8.16675 6.2165 8.16675 5.25C8.16675 4.2835 7.38325 3.5 6.41675 3.5C5.45025 3.5 4.66675 4.2835 4.66675 5.25C4.66675 6.2165 5.45025 7 6.41675 7Z"
      fill="white"
    />
    <path
      d="M6.125 5.83301C6.60825 5.83301 7 5.44126 7 4.95801C7 4.47476 6.60825 4.08301 6.125 4.08301C5.64175 4.08301 5.25 4.47476 5.25 4.95801C5.25 5.44126 5.64175 5.83301 6.125 5.83301Z"
      fill="black"
    />
    <path
      d="M19.8334 2.91699H8.72788C9.93422 4.25049 11.494 6.68824 8.7063 9.91699H19.8334C21.122 9.91699 17.5 7.70558 17.5 6.41699C17.5 5.12841 21.122 2.91699 19.8334 2.91699Z"
      fill="#BBDDF5"
    />
  </svg>
);

export default GlitchEmoji;
